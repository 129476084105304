import React, { useEffect } from 'react';
import { Jumbotron } from 'react-bootstrap';
// import { Mail } from 'react-feather';
import Fade from 'react-reveal/Fade';
// import Mailchimp from 'react-mailchimp-form';
// import BaciJumbotron from './BaciJumbotron';
// import BaciSlider from './BaciSlider';
// import MenuSection from './MenuSection';
// import Footer from './Footer';

const Homepage = () => {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src =
  //     'https://www.opentable.com/widget/reservation/loader?rid=65704&type=standard&theme=tall&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website';
  //   script.async = true;
  //   const ressoDiv = document.getElementsByClassName('ressoConatiner');
  //   ressoDiv[0].appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <div>
      {/* <BaciJumbotron
        h2="Sarasota's Favorite Italian Restaurant"
        video="/video/cafe-baci-header.mp4"
        // image="/img/dining-room.jpg"
      />
      <Jumbotron className="bg-red has-bg large-red-jumbotron padding-top-0">
        <Fade bottom delay={1000}>
          <Container className="section-1" style={{ marginTop: '50px' }}>
            <h2>Serving authentic Italian cuisine for more</h2>
            <h2> than 20 years, from our family to yours</h2>
            <h3>
              <strong>
                <em>
                  Open <br /> Tuesday - Sunday <br /> 4 - 9:30 PM
                </em>
              </strong>
            </h3>
          </Container>
        </Fade>
      </Jumbotron>
      <Fade>
        <Jumbotron className="bg-white has-bg large-white-jumbotron padding-top-0">
          <MenuSection />
        </Jumbotron>
      </Fade> */}

      <Fade bottom>
        <Jumbotron
          id="about"
          className="about-section bg-red has-bg large-red-jumbotron"
        >
          <div className="about-container">
            <div className="about-row">
              <div className="about-col-1">
                {' '}
                <h1>Cafe Baci</h1>
                <h3>
                  Cafe Baci has proudly served Sarasota for 30+ years, and we have closed our doors for the last time. We would like to thank all of our customers for their support over the years. We are grateful for the opportunity to have served you and your families.
                </h3>
              </div>
              {/* <div className="about-col-2">
                <img
                  alt="pasta"
                  className="pasta-plate"
                  src="/img/pasta-plate.png"
                />
              </div> */}
            </div>
          </div>
        </Jumbotron>
      </Fade>

      {/* <Fade bottom>
        <div>
          <BaciSlider />
        </div>
      </Fade>

      <Fade bottom>
        <Jumbotron className="signup-jumbo bg-red has-bg large-red-jumbotron">
          <Container className="signup-container">
            <Row className="signup--row">
              <Col xs={12} md={6} className="signup--col">
                <Mail
                  color="white"
                  strokeWidth="1"
                  height="200px"
                  width="200px"
                />
              </Col>
              <Col xs={12} md={6}>
                <h3>Signup for news and special offers!</h3>
                <Mailchimp
                  action="https://cafebacisarasota.us2.list-manage.com/subscribe/post?u=a18d4ced9ffbeb0e6e7ae5c12&amp;id=af2b96eaef"
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'Email',
                      type: 'email',
                      required: true,
                    },
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      </Fade>

      <Jumbotron
        id="reservation"
        className="resso-jumbo bg-white has-bg large-white-jumbotron padding-top-0"
      >
        <Container>
          <div className="ressoConatiner" />
        </Container>
      </Jumbotron>
      <Footer /> */}
    </div>
  );
};

export default Homepage;
