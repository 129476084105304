import React from 'react';
import { hydrate, render } from 'react-dom';
import ReactGa from 'react-ga';
import App from './App';
import * as serviceWorker from './serviceWorker';


ReactGa.initialize('UA-29920941-1');
ReactGa.pageview(window.location.pathname + window.location.search);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
